import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import userInfoAtom from '../../../../recoil/atom/login/userInfoAtom'
import { getCurrDateString, getCurrMonth, getCurrYear } from '../../../../UTILS/basic/getDate'
import SideBarMenu from './Components/SideBarMenu'
import SideBarLogo from './Components/Logo/SideBarLogo'
import SideBarDojangInfo from './Components/DojangInfo/SideBarDojangInfo'
import { dojangPaymentSelector } from '../../../../recoil/atom/dojangInfoAtom'
import { Chip } from '@mui/material'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const SideBar = () => {
  const currYear = getCurrYear().toString()
  const currMonth = getCurrMonth().toString()
  const currDateString = getCurrDateString()

  const kwanjangMenu = useMemo(() => {
    return [
      {
        name: 'home',
        title: '홈',
        path: '/home',
        src: `${s3URL}/asset/images/icon/side_menu/home.png`,
      },
      {
        name: 'dashboard',
        title: '대시보드',
        path: '/dashboard',
        src: `${s3URL}/asset/images/icon/side_menu/dashboard.png`,
      },
      {
        name: 'invite',
        title: '초대장',
        src: `${s3URL}/asset/images/icon/side_menu/invite.png`,
        sub: [
          { title: '조회', path: '/invitation/inquiry' },
          { title: '초대장 발송', path: '/invitation/send' },
        ],
      },
      {
        name: 'manage',
        title: '도장 관리',
        src: `${s3URL}/asset/images/icon/side_menu/dojang.png`,
        sub: [
          { title: '도장', path: '/manage/dojang' },
          { title: '사범', path: '/manage/sabum/list', params: { class: 0 } },
          { title: '반', path: '/manage/class/list' },
          { title: '차량', path: '/manage/bus' },
          { title: '급띠체계', path: '/manage/levelsystem' },
        ],
      },
      {
        name: 'manage-student',
        title: '수련생',
        src: `${s3URL}/asset/images/icon/side_menu/student.png`,
        sub: [
          // { title: '전체 조회', path: '/manage/student/list', params: { class: 0 } },
          { title: '전체 조회', path: '/manage/student/list', params: { class: 0 } },
          { title: '휴관 수련생 조회', path: '/manage/student/rest/list' },
          { title: '수련생 등록', path: '/manage/student/regist' },
        ],
      },
      {
        name: 'attendance',
        title: '출결',
        src: `${s3URL}/asset/images/icon/side_menu/attendance.png`,
        sub: [
          { title: '조회', path: '/attendance/status', params: { class: 0, y: currYear, m: currMonth } },
          { title: '출결 관리', path: '/attendance/regist', params: { class: 0 } },
        ],
      },
      {
        name: 'bill',
        title: '납부',
        src: `${s3URL}/asset/images/icon/side_menu/bill.png`,
        sub: [
          {
            title: '납부관리',
            path: '/bill/management',
            params: { y: currYear, m: currMonth, bt: '0', pt: '0', st: '0' },
            // new: true,
          },
          {
            title: '청구서 조회',
            path: '/bill/read',
            params: { y: currYear, m: currMonth },
            // new: true,
          },
          {
            title: '청구서 발행',
            path: '/bill/send',
            params: { y: currYear, m: currMonth },
            // new: true,
          },
          {
            title: '설정',
            path: '/bill/settings',
            // new: true,
          },
        ],
      },
      {
        name: 'levelup',
        title: '승급',
        src: `${s3URL}/asset/images/icon/side_menu/levelup.png`,
        sub: [
          {
            title: 'sidemenu.levelup.history',
            path: '/levelup/history',
            params: { class: 0, y: currYear, m: currMonth, level: '0' },
          },
          { title: '승급 등록', path: '/levelup/regist/select', params: { class: 0, level: 0 } },
        ],
      },
      {
        name: 'fitness',
        title: '체력평가',
        src: `${s3URL}/asset/images/icon/side_menu/healthcare.png`,
        sub: [
          {
            title: '조회',
            path: '/fitness/list',
            params: { y: currYear, m: currMonth },
            new: true,
          },
          {
            title: '측정기록 등록',
            path: '/fitness/regist',
            params: { d: currDateString },
            new: true,
          },
          {
            title: '측정종목 관리',
            path: '/fitness/management',
            // params: { class: 0, y: currYear, m: currMonth },
            new: true,
          },
        ],
      },
      {
        name: 'health-care',
        title: '헬스케어',
        // path: '/healthcare',
        src: `${s3URL}/asset/images/icon/side_menu/healthcare.png`,
        sub: [
          {
            title: '측정결과 조회',
            path: '/healthcare/list',
            params: { class: 0, y: currYear, m: currMonth },
            new: true,
          },
          // { title: '헬스케어', path: '/healthcare/detail' },
        ],
      },
      {
        name: 'app',
        title: '앱',
        src: `${s3URL}/asset/images/icon/side_menu/app.png`,
        sub: [
          { title: '공지', path: '/app/notice/list', params: { class: 0, y: currYear, m: currMonth } },
          { title: '알림장', path: '/app/note/list', params: { class: 0, y: currYear, m: currMonth } },
          { title: '일정', path: '/app/schedule/list' },
        ],
      },
    ]
  }, [currYear, currMonth])

  const sabumMenu = useMemo(() => {
    return [
      {
        name: 'home',
        title: '홈',
        path: '/home',
        src: `${s3URL}/asset/images/icon/side_menu/home.png`,
      },
      {
        name: 'dashboard',
        title: '대시보드',
        path: '/dashboard',
        src: `${s3URL}/asset/images/icon/side_menu/dashboard.png`,
      },
      {
        name: 'invite',
        title: '초대장',
        src: `${s3URL}/asset/images/icon/side_menu/invite.png`,
        sub: [
          { title: '조회', path: '/invitation/inquiry' },
          { title: '초대장 발송', path: '/invitation/send' },
        ],
      },
      {
        name: 'manage',
        title: '도장 관리',
        src: `${s3URL}/asset/images/icon/side_menu/dojang.png`,
        sub: [
          { title: '반', path: '/manage/class/list' },
          { title: '차량', path: '/manage/bus' },
          // { title: '급띠체계', path: '/manage/levelsystem' },
        ],
      },
      {
        name: 'manage-student',
        title: '수련생',
        src: `${s3URL}/asset/images/icon/side_menu/student.png`,
        sub: [
          { title: '조회', path: '/manage/student/list', params: { class: 0 } },
          { title: '수련생 등록', path: '/manage/student/regist' },
        ],
      },
      {
        name: 'attendance',
        title: '출결',
        src: `${s3URL}/asset/images/icon/side_menu/attendance.png`,
        sub: [
          { title: '등록', path: '/attendance/regist', params: { class: 0 } },
          { title: '조회', path: '/attendance/status', params: { class: 0, y: currYear, m: currMonth } },
        ],
      },
    ]
  }, [])

  // payemtInfo
  const paymentInfo = useRecoilValue(dojangPaymentSelector)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const servicePaymentChip =
    paymentInfo?.state === 0 ? (
      // exp_ymd 초과
      <Chip
        color='error'
        size='small'
        label='결제필요'
        // variant='outlined'
        style={{ fontSize: '12px', height: '20px', cursor: 'pointer' }}
      />
    ) : paymentInfo?.state === 2 ? (
      // exp_ymd 3일전
      <Chip
        color='warning'
        size='small'
        label='결제필요'
        // variant='outlined'
        style={{ fontSize: '12px', height: '20px', cursor: 'pointer' }}
      />
    ) : (
      ''
    )

  const otherKwanjangMenu = useMemo(
    () => [
      // {
      //   title: '서비스 결제',
      //   path: '/service/payment',
      //   iconUrl: `${s3URL}/asset/images/icon/side_menu/service_payment.png`,
      //   chip: servicePaymentChip,
      // },
      {
        title: '서비스 문의',
        path: '/service/inquiry',
        iconUrl: `${s3URL}/asset/images/icon/side_menu/service_inquiry.png`,
      },
      {
        title: '사용자 가이드',
        path: '/guide',
        iconUrl: `${s3URL}/asset/images/icon/side_menu/service_guide.png`,
        newTab: true,
      },
    ],
    [servicePaymentChip],
  )

  const otherSabumMenu = useMemo(
    () => [
      {
        title: '서비스 문의',
        path: '/service/inquiry',
        iconUrl: `${s3URL}/asset/images/icon/side_menu/service_inquiry.png`,
      },
      {
        title: '사용자 가이드',
        path: '/guide',
        iconUrl: `${s3URL}/asset/images/icon/side_menu/service_guide.png`,
      },
    ],
    [],
  )

  // console.log('asdf')

  const { id, user_role } = useRecoilValue(userInfoAtom)
  const role = user_role === 'KW' ? 'kwanjang' : 'sabum'

  return (
    <>
      <div className='side-container'>
        <div className='fixed z-[9999]'>
          <div className='side-profile'>
            {/* 태권월드 로고 & 프로필 */}
            <SideBarLogo />
          </div>
          {/* 도장정보 */}
          <SideBarDojangInfo />
        </div>

        {/* 메뉴 */}
        <SideBarMenu
          menu={role === 'kwanjang' ? kwanjangMenu : sabumMenu}
          otherMenu={role === 'kwanjang' ? otherKwanjangMenu : otherSabumMenu}
        />
      </div>
    </>
  )
}

export default SideBar
